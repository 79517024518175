<template>
  <Dialog :common-dialog="mailDialog" :dialog-width="dialogWidth">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <v-container class="px-10" fluid>
        <v-form
          ref="mail_form"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="submit()"
        >
          <v-layout class="mb-4">
            <template v-if="isUser">
              <v-flex md3 class="my-auto">
                <label class="font-weight-500 font-size-16 required"
                  >Email template</label
                >
              </v-flex>
              <v-flex md9>
                <v-select
                  dense
                  filled
                  solo
                  flat
                  outlned
                  hide-details
                  v-model="emailTemplate"
                  :items="emailTemplateList"
                  placeholder="Email template"
                ></v-select>
              </v-flex>
            </template>
          </v-layout>
          <v-layout class="mb-4">
            <v-flex md3 class="my-auto">
              <label class="font-weight-500 font-size-16 required"
                >Recipients</label
              >
            </v-flex>
            <v-flex md9>
              <v-combobox
                chips
                :items="autocompleteEmails()"
                v-model="email.recipients"
                placeholder="Recipients"
                label="Recipients"
                multiple
                flat
                :disabled="formLoading"
                v-on:change="validateTagEmail(email.recipients)"
                hide-details
                solo
                :rules="[
                  validateRules.required(email.recipients, 'Recipients'),
                ]"
              >
                <template v-slot:append-outer>
                  <v-chip
                    v-on:click="emailCCShow = !emailCCShow"
                    class="append-outer-chip"
                    label
                    :disabled="formLoading"
                    :color="emailCCShow ? 'green' : ''"
                    :text-color="emailCCShow ? 'white' : ''"
                  >
                    <span class="font-weight-500 font-size-16">CC</span>
                  </v-chip>
                </template>
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    label
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click:close="removeEmail(item, 'recipients')"
                  >
                    <span class="font-weight-500 font-size-16">{{ item }}</span>
                  </v-chip>
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
          <v-layout v-if="emailCCShow" class="my-4">
            <v-flex md3 class="my-auto">
              <label class="font-weight-500 font-size-16">CC</label>
            </v-flex>
            <v-flex md9>
              <v-combobox
                v-model="email.cc"
                chips
                :disabled="formLoading"
                :items="autocompleteEmails()"
                placeholder="CC"
                label="CC"
                multiple
                flat
                v-on:change="validateTagEmail(email.cc)"
                hide-details
                solo
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    label
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click:close="removeEmail(item, 'cc')"
                  >
                    <span class="font-weight-500 font-size-16">{{ item }}</span>
                  </v-chip>
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
          <v-layout class="my-4">
            <v-flex md3 class="my-auto">
              <label class="font-weight-500 font-size-16 required"
                >Subject</label
              >
            </v-flex>
            <v-flex md9>
              <v-text-field
                v-model="email.subject"
                dense
                filled
                label="Subject"
                placeholder="Subject"
                solo
                flat
                :disabled="formLoading"
                hide-details
                color="cyan"
                :rules="[validateRules.required(email.subject, 'Subject')]"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout class="my-4">
            <v-flex md12>
              <TinyMCE
                :disabled="formLoading"
                v-model="email.message"
              ></TinyMCE>
            </v-flex>
          </v-layout>
          <v-layout class="mt-4" v-if="!isUser">
            <v-flex md12>
              <FileTemplate
                :disabled="formLoading"
                v-on:file:updated="updateAttachment($event)"
                allowUpload
              ></FileTemplate>
            </v-flex>
          </v-layout>

          <v-layout class="mt-4" v-if="!isUser">
            <v-flex md12>
              <table class="width-100">
                <tr>
                  <td width="100%" align="left">
                    <label class="font-weight-500 font-size-16 required"
                      >Admin Signature:
                    </label>
                  </td>
                </tr>
                <tr class="signature-pad" id="signature-pad">
                  <td width="100%" align="left" valign="center">
                    <v-img
                      contain
                      v-if="login_signature"
                      :lazy-src="$defaultImage"
                      style="width: 350px; height: 350px"
                      :src="login_signature"
                    ></v-img>
                    <div
                      v-else
                      ref="admin_signature_div"
                      style="width: 100%"
                      class="custom-border-grey-dashed"
                    >
                      <canvas ref="admin_signature"></canvas>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td align="left" width="100%" valign="center">
                    <div class="w-50">
                      Authorized Signature
                      <p class="float-right m-0" v-if="!login_signature">
                        <v-btn
                          content="Click here to clear signature"
                          v-tippy="{ arrow: true, placement: 'top' }"
                          icon
                          small
                          class="float-right"
                          v-on:click="admin_signature.clear()"
                        >
                          <v-icon>mdi-close-circle-outline</v-icon>
                        </v-btn>
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="close_dialog()"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Close
      </v-btn>

      <v-btn
        v-on:click="submit()"
        :disabled="validSignature"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        depressed
      >
        <v-icon left>mdi-send mdi-rotate-315</v-icon>Send
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import { validateEmail } from "@/core/plugins/validation-mixin";
import { GET, POST } from "@/core/services/store/request.module";
import SignaturePad from "signature_pad";
export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    mailDialog: {
      type: Boolean,
      default: false,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      formLoading: false,
      emailCCShow: false,
      attachments: [],
      admin_signature: null,
      login_signature: null,
      emailTemplate: null,
      emailTemplateList: [],
      email: {
        recipients: [],
        cc: [],
        subject: null,
        message: null,
      },
    };
  },
  watch: {
    mailDialog(param) {
      if (param) {
        this.init();
      }
    },
  },
  methods: {
    close_dialog() {
      this.$emit("close", true);

      this.email = {
        recipients: [],
        cc: [],
        subject: null,
        message: null,
      };
    },
    init() {
      const _this = this;
      _this.formLoading = true;
      _this.$store
        .dispatch(GET, {
          url: _this.type + "/" + _this.typeId + "/send-mail",
        })
        .then(({ data }) => {
          _this.email = new Object({
            recipients: data.person_emails,
            cc: [],
            subject: data.email_subject,
            message: data.email_body,
            action: "send_as_email",
          });
          _this.login_signature = data.loginUserSigature;
          if (!_this.login_signature) {
            _this.initSignature();
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    convert_base_64(file) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            function () {
              resolve(reader.result);
            },
            false
          );
          reader.readAsDataURL(file);
        } catch (error) {
          reject(error);
        }
      });
    },
    submit() {
      const _this = this;
      _this.formLoading = true;
      _this.email.attachments = _this.attachments;
      _this.email.login_signature = _this.login_signature
        ? _this.login_signature
        : null;
      _this.email.admin_signature = _this.admin_signature
        ? _this.admin_signature.toDataURL()
        : null;
      /*  if(_this.login_signature){
        _this.email.attachments = _this.login_signature; 
      }else{
        _this.email.admin_signature = _this.admin_signature.toDataURL();
      } */

      _this.$store
        .dispatch(POST, {
          url: _this.type + "/" + _this.typeId + "/send-mail",
          data: _this.email,
        })
        .then(() => {
          _this.$emit("success", true);
          _this.close_dialog();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    updateAttachment(param) {
      this.attachments = param;
    },
    validateTagEmail(emails) {
      for (let i = 0; i < emails.length; i++) {
        if (!validateEmail(emails[i])) {
          emails.splice(i, 1);
        }
      }
    },
    removeEmail(email, type) {
      const emails = this.email[type];
      const index = this.lodash.findIndex(emails, (row) => email == row);
      if (index >= 0) {
        this.email[type].splice(index, 1);
      }
    },
    autocompleteEmails() {
      return this.lodash.map(this.recipients, (row) => row.email);
    },
    initSignature() {
      const _this = this;
      _this.$nextTick(() => {
        let ccanvas = _this.$refs["admin_signature"];
        let ccanvasDiv = _this.$refs["admin_signature_div"];
        let cparentDiv = document.getElementById("signature-pad");
        let cparentWidth = cparentDiv.offsetWidth / 2;
        ccanvas.setAttribute("width", cparentWidth);
        ccanvasDiv.setAttribute("style", "width:" + cparentWidth + "px");
        _this.admin_signature = new SignaturePad(ccanvas);
      });
    },
  },
  components: {
    Dialog,
    TinyMCE,
    FileTemplate,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    validSignature() {
      return this.admin_signature ? this.admin_signature.isEmpty() : false;
    },
  },
};
</script>
<style type="text/css" scoped>
.signature-pad {
  width: 50% !important;
}
</style>

<template>
  <!--begin::Ticket Internal Listing-->
  <v-container fluid>
    <v-layout>
      <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
        <template v-if="milestoneList[0]?.milestone_invoice[0]?.status != 1">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button custom-btn-color white--text"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="selectInvoiceType"
              >
                <v-icon>mdi-plus</v-icon> create
              </v-btn>
            </template>
            <span>Create New Milestone</span>
          </v-tooltip>
        </template>
        <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              tile
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:click="getAllMilestones"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>Reload</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <ListingTable
      :columnCount="4"
      :dataLoading="dataLoading"
      :rowData="milestoneList"
    >
      <template v-slot:thead>
        <thead style="position: sticky; top: 0; z-index: 99">
          <tr>
            <th class="simple-table-th">Milestone #</th>
            <th class="simple-table-th">Name</th>
            <th class="simple-table-th">Description</th>
            <th class="simple-table-th">Dates</th>
            <th class="simple-table-th">Milestones</th>
            <th class="simple-table-th">Invoice</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th">Action</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="milestoneList.length > 0">
            <tr :key="index" v-for="(data, index) in milestoneList">
              <td class="simple-table-td">
                <Barcode :barcode="data.barcode"></Barcode>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis">
                  <template v-if="data && data.name">
                    {{ data.name }}
                  </template>
                </p>
              </td>
              <td class="simple-table-td">
                <div
                  class="custom-nowrap-ellipsis"
                  style="
                    max-width: 500px !important;
                    white-space: pre-wrap !important;
                  "
                >
                  <read-more
                    class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                    more-str="read more"
                    :text="data?.description || '<em>No Description</em>'"
                    link="#"
                    less-str="read less"
                    :max-chars="30"
                  >
                  </read-more>
                </div>
              </td>
              <td class="simple-table-td">
                <div class="project-listing-dates">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Date: </b>
                    <template v-if="data && data.date">
                      {{ formatDate(data.date) }}
                    </template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Reminder: </b>
                    <template v-if="data.reminder_date">{{
                      formatDate(data.reminder_date)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">Not Reminder</em></template
                    >
                  </p>
                </div>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Milestone Amount: </b>
                  <template v-if="data && data.amount">
                    {{ formatMoney(data.amount) }}
                  </template>
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Milestone Percentage: </b>
                  <template v-if="data && data.progress">
                    {{ getRoundData(data.progress) }} %
                  </template>
                </p>
              </td>
              <td
                class="simple-table-td"
                v-on:click.stop.prevent="
                  openInvoics(data.milestone_invoice, data)
                "
              >
                <div class="m-0 custom-nowrap-ellipsis">
                  <div class="d-flex">
                    <div
                      v-if="
                        data?.milestone_invoice &&
                        data?.milestone_invoice[0] &&
                        data?.milestone_invoice[0].barcode
                      "
                    >
                      <v-chip
                        label
                        color="#24326d"
                        class="p-3 mt-1"
                        v-if="data.milestone_invoice[0]"
                        outlined
                        small
                        >{{ data.milestone_invoice[0].barcode }}</v-chip
                      >
                      <template v-if="data?.milestone_invoice?.length > 1">
                        <div style="margin-left: 0px">
                          <v-chip small color="blue white--text" label>
                            <span class="font-size-16 font-weight-500">
                              +{{
                                data.milestone_invoice.length - 1
                              }}
                              More</span
                            >
                          </v-chip>
                        </div>
                      </template>
                    </div>
                    <em v-else class="text-muted mr-1"> no invoice </em>
                    <v-spacer></v-spacer>
                    <div>
                      <template
                        v-if="
                          data?.milestone_invoice[0]?.milestone_payment_type !=
                          'fully_payment'
                        "
                      >
                        <v-tooltip
                          top
                          content-class="custom-top-tooltip"
                          z-index="99"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              depressed
                              fab
                              dark
                              x-small
                              :color="'cyan'"
                              v-bind="attrs"
                              v-on="on"
                              style="border-radius: 24px !important"
                              v-on:click.stop.prevent="partiallyPayment(data)"
                              class="mr-3 custom-disabled"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Create Invoice</span>
                        </v-tooltip>
                      </template>
                    </div>
                  </div>

                  <div>
                    <template
                      v-if="
                        data?.milestone_invoice[0]?.milestone_payment_type ==
                        'fully_payment'
                      "
                    >
                      <v-chip
                        label
                        color="green"
                        class="text-white p-3 mt-1"
                        outlined
                        small
                        >100 % Invoice</v-chip
                      >
                    </template>
                  </div>
                </div>
                <!--  <v-icon    size="30" v-on:click.stop.prevent="invoiceDialog(data.id)">mdi-file-document-outline</v-icon> -->
              </td>
              <td class="simple-table-td">
                <div
                  v-on:click.stop
                  class="d-flex justify-space-between align-center mb-1"
                >
                  <v-menu
                    offset-y
                    min-width="100px"
                    style="padding: 0px 4px"
                    rounded
                    :disabled="data.status == 1"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex justify-space-between align-center"
                        style="
                          padding: 0px 6px;
                          background-color: #f7faff;
                          width: 110px;
                          border-radius: 9px;
                        "
                        :style="`border:1px solid ${getColor(data.status)}`"
                      >
                        <span
                          :class="`text-capitalize font-level-1 ${getTextColor(
                            data.status
                          )}`"
                        >
                          <template v-if="data.status == 1">
                            Complete
                          </template>
                          <template v-else> Pending </template>
                        </span>
                        <v-icon
                          style="font-size: 22px"
                          :color="data.color"
                          small
                          >mdi-menu-down</v-icon
                        >
                      </div>
                    </template>
                    <v-list elevation>
                      <v-list-item
                        v-on:change="openConfirm(item.value, data)"
                        class="list-item cursor-pointer p-1"
                        v-for="(item, index) in statusUpdateList"
                        :key="index"
                      >
                        <v-list-item-title class="pl-1">
                          <v-chip
                            style="height: 15px; width: 15px; padding: 0"
                            :color="item.color"
                          >
                          </v-chip>
                          {{ item.text }}</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </td>
              <td class="simple-table-td">
                <p class="m-0 custom-nowrap-ellipsis">
                  <v-tooltip
                    top
                    content-class="custom-top-tooltip"
                    z-index="99"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="data.status != 1"
                        depressed
                        fab
                        dark
                        x-small
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                        style="border-radius: 24px !important"
                        v-on:click="getSingle(data.uuid)"
                        class="mr-3 custom-disabled"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    content-class="custom-top-tooltip"
                    z-index="99"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        v-if="data.status != 1"
                        fab
                        dark
                        x-small
                        color="red"
                        class="mr-3 custom-disabled-del"
                        v-bind="attrs"
                        v-on="on"
                        style="border-radius: 24px !important"
                        v-on:click="deleteData(data)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </p>
              </td>
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="5">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no milestone at the moment.
              </p>
            </td>
          </tr>
        </tbody>
        <Dialog :commonDialog="milestoneDialog" :dialogWidth="900">
          <template v-slot:title
            ><template v-if="milestoneCreate.uuid">Update</template>
            <template v-else>Create</template> Milestones</template
          >
          <template v-slot:body>
            <v-container fluid class="pt-0" v-if="milestoneDialog">
              <v-form
                ref="milestoneForm"
                v-model.trim="formValid"
                lazy-validation
                v-on:submit.stop.prevent="createOrUpdate"
              >
                <v-row>
                  <v-col md="12" class="pt-0">
                    <table class="width-100">
                      <tr>
                        <td colspan="2" class="py-2">
                          <label class="font-weight-500 font-size-16 required">
                            Name</label
                          >
                          <v-text-field
                            v-model.trim="milestoneCreate.name"
                            dense
                            filled
                            color="cyan"
                            label=" Name"
                            :disabled="dataLoading"
                            :loading="dataLoading"
                            solo
                            flat
                            :rules="[
                              validateRules.required(
                                milestoneCreate.name,
                                ' Name'
                              ),
                              validateRules.required(
                                milestoneCreate.name,
                                ' Name'
                              ),
                              validateRules.minLength(
                                milestoneCreate.name,
                                ' Name',
                                1
                              ),
                              validateRules.maxLength(
                                milestoneCreate.name,
                                ' Name',
                                100
                              ),
                            ]"
                          ></v-text-field>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="2" class="py-2">
                          <label class="font-weight-500 font-size-16">
                            Description</label
                          >
                          <v-textarea
                            v-model.trim="milestoneCreate.description"
                            auto-grow
                            dense
                            filled
                            :disabled="dataLoading"
                            :loading="dataLoading"
                            color="cyan"
                            label="Description"
                            solo
                            flat
                            :rules="[
                              validateRules.minLength(
                                milestoneCreate.description,
                                'Description',
                                1
                              ),
                              validateRules.maxLength(
                                milestoneCreate.description,
                                'Description',
                                100
                              ),
                            ]"
                            v-on:keypress="(e) => manageLimit(e)"
                            v-on:paste="(e) => onPaste(e)"
                            row-height="15"
                          ></v-textarea>
                          <div class="text-right">
                            {{
                              milestoneCreate.description
                                ? milestoneCreate.description.length
                                : 0
                            }}/100
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td class="py-2">
                          <label class="font-weight-500 font-size-16"
                            >Date</label
                          >
                          <DatePicker :dataLoading="dataLoading" solo
                          :placeholder="'Date *'" v-model="milestoneCreate.date"
                          :default-date=" milestoneCreate.date " mandatory: true
                          />
                        </td>
                        <td class="py-2">
                          <label class="font-weight-500 font-size-16"
                            >Reminder Date
                          </label>
                          <DatePicker
                            :dataLoading="dataLoading"
                            solo
                            :default-date="milestoneCreate.reminder_date"
                            :placeholder="'Reminder Date '"
                            v-model="milestoneCreate.reminder_date"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td class="py-2" valign="top">
                          <label class="font-weight-500 font-size-16"
                            >Amount Type
                          </label>
                          <v-autocomplete
                            dense
                            color="cyan"
                            filled
                            :items="amountTypeList"
                            :disabled="dataLoading"
                            :loading="dataLoading"
                            v-model.trim="milestoneCreate.amount_type"
                            label="Amount Type"
                            solo
                            flat
                            item-color="cyan"
                            item-text="text"
                            item-value="value"
                            hide-details
                            :rules="[
                              validateRules.required(
                                milestoneCreate.amount_type,
                                'Amount Type'
                              ),
                            ]"
                          >
                            <template v-slot:no-data>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-html="'No Amount Type Found.'"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                          </v-autocomplete>
                        </td>
                        <td class="py-2">
                          <template v-if="milestoneCreate.amount_type == 1">
                            <label class="font-weight-500 font-size-16 required"
                              >Milestone Amount</label
                            >
                            <v-text-field
                              dense
                              color="cyan"
                              filled
                              :disabled="dataLoading"
                              :loading="dataLoading"
                              prepend-inner-icon="mdi-currency-usd"
                              hide-spin-buttons
                              v-on:keyup="changeMilestoneAmount"
                              type="number"
                              v-on:keypress="
                                limitDecimal($event, milestoneCreate.amount)
                              "
                              v-model="milestoneCreate.amount"
                              v-model.trim.number.lazy="milestoneCreate.amount"
                              label="Milestone Amount"
                              :rules="[
                                validateRules.required(
                                  milestoneCreate.amount,
                                  'Milestone Amount'
                                ),
                                validateRules.minLength(
                                  milestoneCreate.amount,
                                  'Milestone Amount',
                                  1
                                ),
                                validateRules.maxLength(
                                  milestoneCreate.amount,
                                  'Milestone Amount',
                                  12
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                            ></v-text-field>
                          </template>
                          <template v-if="milestoneCreate.amount_type == 2">
                            <label class="font-weight-500 font-size-16 required"
                              >Milestone Percentage</label
                            >
                            <v-text-field
                              dense
                              color="cyan"
                              filled
                              :disabled="dataLoading"
                              :loading="dataLoading"
                              append-icon="mdi-percent"
                              type="number"
                              @keypress="percentageKeyup"
                              @keyup="checkPercentage"
                              v-model="milestoneCreate.milestone_percentage"
                              v-model.trim.number.lazy="
                                milestoneCreate.milestone_percentage
                              "
                              label="Milestone Percentage"
                              :rules="[
                                validateRules.required(
                                  milestoneCreate.milestone_percentage,
                                  'Milestone Percentage'
                                ),
                                validateRules.minLength(
                                  milestoneCreate.milestone_percentage,
                                  'Milestone Percentage',
                                  1
                                ),
                                validateRules.maxLength(
                                  milestoneCreate.milestone_percentage,
                                  'Milestone Percentage',
                                  100
                                ),
                              ]"
                              solo
                              flat
                              hide-details
                            ></v-text-field>
                          </template>
                          <p
                            class="text-black mt-1 pl-1 mb-1"
                            v-if="milestoneCreate.amount_type == 2"
                          >
                            <b
                              >Milestone Amount :
                              <span
                                v-if="calculatedMilestoneAmountByPercentage"
                              >
                                {{
                                  formatMoney(
                                    calculatedMilestoneAmountByPercentage
                                  )
                                }}</span
                              >

                              <!-- {{ detail.project_value }} --></b
                            >
                          </p>
                          <p class="text-black mt-1 pl-1 mb-1">
                            <b
                              >Quotation Balance :
                              <span v-if="milestoneCreate.amount_type == 1">
                                {{
                                  formatMoney(
                                    projectBalance - milestoneCreate.amount
                                  )
                                }}</span
                              >
                              <span v-else>
                                {{
                                  formatMoney(
                                    projectBalance -
                                      calculatedMilestoneAmountByPercentage
                                  )
                                }}</span
                              >
                              <!-- {{ detail.project_value }} --></b
                            >
                          </p>
                          <p class="text-black pl-1 mb-0">
                            <b
                              >Quotation Amonut :
                              {{ formatMoney(detail.project_value) }}</b
                            >
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="py-2">
                          <label class="font-weight-500 font-size-16">
                            Attachments</label
                          >
                          <!--  <FileTemplate
                            allowUpload
                            isMinDisplay
                            :attachments="milestoneCreate.attachments"
                            v-on:file:updated="updateFiles"
                          ></FileTemplate> -->
                          <FileTemplate
                            :attachments="detail.attachments"
                            v-on:file:updated="updateFiles"
                            allowUpload
                          ></FileTemplate>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </template>
          <template v-slot:action>
            <v-container class="py-0">
              <v-row>
                <v-col md="12" class="text-right">
                  <v-btn
                    v-on:click="milestoneDialog = false"
                    :disabled="dataLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                  >
                    Cancel
                  </v-btn>
                  <!--  <v-btn
                      :disabled="!formValid || dataLoading"
                      :loading="dataLoading"
                      class="mx-2 custom-bold-button white--text"
                      v-on:click="checkInvoice"
                      color="cyan"
                    >
                      Save
                    </v-btn> -->
                  <v-btn
                    :disabled="!formValid || dataLoading"
                    :loading="dataLoading"
                    class="mx-2 custom-bold-button white--text"
                    v-on:click="createOrUpdate()"
                    color="cyan"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Dialog>
        <template>
          <Dialog :commonDialog="confirmDialog">
            <template v-slot:title>Confirmation</template>
            <template v-slot:body>
              <v-container fluid class="py-0">
                <v-row>
                  <v-col lg="12">
                    <RadioInputCustom
                      v-model="updateTargetType"
                      row
                      color="blue darken-4"
                      :items="[
                        {
                          label: '100 % Invoice ?',
                          value: 'fully_payment',
                        },
                        {
                          label: `Payment in Milestones ?`,
                          value: 'partially_payment',
                        },
                      ]"
                    ></RadioInputCustom>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:action>
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                :disabled="dataLoading"
                v-on:click="createMileStoneInvoice"
              >
                Continue
              </v-btn>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="confirmDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
          <Dialog :common-dialog="show_invoices">
            <template v-slot:title
              >Milestone({{ milestone_barcode }}) :- Invoices</template
            >
            <template v-slot:body>
              <v-container fluid class="py-0">
                <v-row>
                  <v-col md="12" class="pt-0 custom-listing-table">
                    <template v-for="(row, EIndex) in milestone_invoice">
                      <table
                        width="100%"
                        class="white-background"
                        :key="EIndex"
                      >
                        <tbody class="custom-border-bottom">
                          <tr :class="{ 'orange lighten-5': EIndex % 2 }">
                            <td
                              class="simple-table-td"
                              v-on:click="viewInvoice(row.id)"
                            >
                              <div class="invoice-listing-customer">
                                <p class="m-0 custom-nowrap-ellipsis">
                                  <b>Invoice #: </b>
                                  <template v-if="row.barcode">
                                    {{ row.barcode }}
                                  </template>
                                </p>
                                <p
                                  class="m-0 custom-nowrap-ellipsis mt-1"
                                  style="display: flex !important"
                                >
                                  <b>Status: </b>
                                  <template v-if="row.status">
                                    <CustomStatus
                                      small
                                      :status="row.status"
                                      endpoint="invoice/status"
                                    ></CustomStatus
                                  ></template>
                                </p>
                                <!--  <p class="m-0 custom-nowrap-ellipsis">
                              <b>Issued Date: </b>
                              <template v-if="row.invoice_date">{{
                                formatDate(row.invoice_date)
                              }}</template> 
                              <template v-else
                                ><em class="text--secondary"
                                  >No Issued Date</em
                                ></template
                              >
                            </p> -->
                              </div>
                            </td>
                            <td
                              class="simple-table-td"
                              v-on:click="viewInvoice(row.id)"
                            >
                              <div class="invoice-listing-customer">
                                <p class="m-0 custom-nowrap-ellipsis mb-1">
                                  <b>Due Date: </b>
                                  <template v-if="row.due_date">{{
                                    formatDate(row.due_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Due Date</em
                                    ></template
                                  >
                                </p>
                                <p class="m-0 custom-nowrap-ellipsis mb-1">
                                  <b>Sent Date: </b>
                                  <template v-if="row.sent_date">{{
                                    formatDate(row.sent_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Sent Date</em
                                    ></template
                                  >
                                </p>
                                <p class="m-0 custom-nowrap-ellipsis mb-1">
                                  <b>Issued Date: </b>
                                  <template v-if="row.invoice_date">{{
                                    formatDate(row.invoice_date)
                                  }}</template>
                                  <template v-else
                                    ><em class="text--secondary"
                                      >No Issued Date</em
                                    ></template
                                  >
                                </p>
                              </div>
                            </td>
                            <td
                              class="simple-table-td"
                              v-on:click="viewInvoice(row.id)"
                            >
                              <div class="invoice-listing-amount">
                                <p class="m-0 custom-nowrap-ellipsis mb-1">
                                  <b>Payble Amount : </b>
                                  <label class="m-0 orange--text">{{
                                    $accountingJS.formatMoney(row.total)
                                  }}</label>
                                </p>
                                <p class="m-0 custom-nowrap-ellipsis mb-1">
                                  <b>Paid Amount: </b>
                                  <label class="m-0 green--text">{{
                                    $accountingJS.formatMoney(row.paid_amount)
                                  }}</label>
                                </p>
                                <p
                                  class="m-0 custom-nowrap-ellipsis mb-1"
                                  v-if="row.pending_amount > 0"
                                >
                                  <b>Balance Amonut: </b>
                                  <label class="m-0 red--text">{{
                                    $accountingJS.formatMoney(
                                      row.pending_amount
                                    )
                                  }}</label>
                                </p>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:action>
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="show_invoices = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template>
      </template>

      >
    </ListingTable>
    <Invoice
      v-if="invoice_dialog"
      :reassign-schedule-dialog="invoice_dialog"
      v-on:close="invoice_dialog = false"
      :milestone-id="milestone_id"
      :project-id="detail.id"
      :updated-id="invoice_id"
      :milestone-data="milestoneParent"
      v-on:success="getAllMilestones"
    ></Invoice>
    <InvoiceView
      :invoice-view-dialog="invoice_view_dialog"
      v-on:close="invoice_view_dialog = false"
      :updated-id="invoice_id"
    ></InvoiceView>
    <DeleteDialog :delete-dialog="deleteDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Milestone
          <span class="font-weight-700" style="color: red">{{
            deleteMilestoneName
          }}</span>
          will be deleted forever and cannot be retrieved later. Are you sure
          about deleting it?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          depressed
          :disabled="dataLoading"
          :loading="dataLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <Dialog :common-dialog="accept_dialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-form ref="statusForm" lazy-validation>
          <v-container class="px-10" fluid style="text-align: center">
            <p class="font-weight-600 font-size-19">
              If you change milestone amount then all related invoice will be
              deleted Are you sure you want to change it?
            </p>
          </v-container>
        </v-form>
      </template>
      <template v-slot:action>
        <template>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="createOrUpdate"
          >
            Okay
          </v-btn>
          <v-btn
            v-on:click="accept_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Cancel
          </v-btn>
        </template>
      </template>
    </Dialog>
    <Dialog :commonDialog="confirmationDialog">
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-row>
          <v-col md="12" class="pt-0">
            <h2 class="font-weight-600 delete-text text-center">
              Are you sure you want to Complete Milestone #
              <span style="color: red">{{ milestone_barcode }}</span>
              ?
            </h2>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="dataLoading"
          :loading="dataLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="updateStatus(updateStatusValue, updateStatusUuid)"
        >
          Yes
        </v-btn>
        <v-btn
          :disabled="dataLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="confirmationDialog = false"
        >
          No
        </v-btn>
      </template>
    </Dialog>
  </v-container>
  <!--end::Ticket Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import {
  /* QUERY, */ DELETE,
  PATCH,
  GET,
  POST,
  PUT,
} from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
/* import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib"; */
/* import TableActivity from "@/view/pages/partials/Table-Activity.vue"; */
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import DeleteDialog from "@/view/pages/partials/Delete";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import Invoice from "@/view/pages/partials/Milestone-Invoice.vue";
import InvoiceView from "@/view/pages/partials/Milestone-Invoice-view.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import RadioInputCustom from "@/view/components/RadioInputCustom.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "timesheet-internal-list",
  data() {
    return {
      updateStatusValue: null,
      updateStatusUuid: null,
      parent: 0,
      invoice_dialog: false,
      invoice_id: 0,
      invoice_view_dialog: false,
      dates: [],
      deleteMilestoneName: null,
      dataRows: [],
      milestone_barcode: null,
      updateTargetType: "fully_payment",

      dataLoading: true,
      deleteUuid: null,
      projectBalance: 0,
      milestoneDialog: false,
      confirmationDialog: false,
      deleteDialog: false,
      accept_dialog: false,
      singleMilestone: {},
      milestoneList: [],
      confirmDialog: false,
      statusUpdateList: [
        { value: 1, text: "Complete", color: "green" },
        { value: 2, text: "Pending", color: "red" },
      ],
      amountTypeList: [
        { value: 1, text: "Dollar" },
        { value: 2, text: "Percentage" },
      ],

      filter: {
        search: null,
      },
      /*   timeout: null,
        timeoutLimit: 500,
        attachments: [], */
      milestone_invoice: [],
      show_invoices: false,
      milestone_id: 0,
      milestoneParent: {},
      milestoneCreate: {
        project: 0,
        name: null,
        description: null,
        date: null,
        uuid: null,
        reminder_date: null,
        amount_type: 2,
        milestone_percentage: 0,
        amount: 0,
        project_balace: null,
        attachments: [],
      },
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "Quotation",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllMilestones();
        }
        /*  this.setProjectBalance(); */
      },
    },
  },
  components: {
    Barcode,
    Dialog,
    DatePicker,
    RadioInputCustom,
    FileTemplate,
    /* TableActivity, */
    DeleteDialog,
    ListingTable,
    CustomStatus,
    Invoice,
    InvoiceView,
  },
  methods: {
    getRoundData(number) {
      if (number) {
        return Number(number).toFixed(2);
      }
    },
    viewInvoice(id) {
      this.$router.push(
        this.getDefaultRoute("invoice.detail", {
          params: { id: id },
        })
      );
    },
    openInvoics(invoice, milestone) {
      this.milestone_barcode = milestone.barcode;
      this.milestone_invoice = invoice;
      this.show_invoices = true;
    },
    getRoundValue(value) {
      let roundedValue = value;
      let roundedString = parseFloat(roundedValue).toFixed(2); // Round to two decimal places
      let roundedNumber = parseFloat(roundedString);
      return roundedNumber;
    },
    createMileStoneInvoice() {
      if (this.updateTargetType == "fully_payment") {
        this.$router.push(
          this.getDefaultRoute("invoice.create", {
            query: {
              payment: "fully_payment",
              quotation: this.detail.id,
              milestone: 0,
              milestone_amount: this.detail.total,
            },
          })
        );
      } else {
        this.createMilestone();
      }
    },
    partiallyPayment(data) {
      /*  let request = 
           {
              quotation: this.detail.id,
              milestone: data.id,
              message: `${this.getRoundValue(data.progress)}% deposit "as per quotation ${this.detail.barcode} dated 27 July  of total amt $ ${this.detail.total}`,
              milestone_amount: this.formatMoney(data.amount),
              milestone_amount_persentage: this.getRoundValue(data.progress),
              quotation_total: this.formatMoney(this.detail.total),
          }; */
      /*  localStorage.setItem("milesttone", JSON.stringify(request)); */
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            payment: "partially_payment",
            quotation: this.detail.id,
            milestone: data.id,
            milestone_amount: data.amount,
            milestone_amount_persentage: this.getRoundValue(data.progress),
            quotation_total: this.detail.total,
            quotation_barcode: this.detail.barcode,
            milestone_name: data.name,
            amount_type: data.amount_type,
            quotation_date: this.formatDate(this.detail.added_at),
          },
        })
      );
    },
    /*   checkInvoice() {
        if (
          this.singleMilestone &&
          this.singleMilestone.uuid &&
          this.milestoneCreate.amount != this.singleMilestone.amount &&
          this.singleMilestone.invoices
        ) {
          this.accept_dialog = true;
        } else {
          this.createOrUpdate();
        }
      }, */
    deleteData(data) {
      this.deleteMilestoneName = data.name;
      this.deleteUuid = data.uuid;
      this.deleteDialog = true;
    },
    invoiceDialog(parent, id) {
      this.invoice_id = 0;
      this.milestone_id = id;
      this.milestoneParent = parent;
      this.invoice_dialog = true;
    },
    invoiceUpdateDialog(parent, milestone, invoice) {
      this.milestone_id = milestone;
      this.milestoneParent = parent;
      this.invoice_id = invoice;
      this.invoice_dialog = true;
    },
    invoiceViewDialog(invoice) {
      this.invoice_id = invoice;
      this.invoice_view_dialog = true;
    },
    attchments() {
      let query = { ...this.$route.query };
      query.tab = "attachment";
      this.$router.push({ query });
    },
    deleteConfirm() {
      this.dataLoading = true;
      this.$store
        .dispatch(DELETE, { url: `project-milestone/${this.deleteUuid}` })
        .then(() => {
          this.deleteUuid = null;
          this.deleteDialog = false;
          this.deleteMilestoneName = null;
          this.getAllMilestones();
          this.$emit("reloadData", true);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    changeMilestoneAmount() {
      if (
        this.milestoneCreate?.amount < 0 ||
        this.milestoneCreate?.amount > this.project_balace
      ) {
        this.milestoneCreate.amount = 0;
      }
      if (this.milestoneCreate?.amount > this.detail.project_value) {
        this.milestoneCreate.amount = this.detail.project_value;
      }
    },
    openConfirm(value, data) {
      if (value == 1) {
        this.milestone_barcode = data.barcode;
        this.updateStatusValue = value;
        this.updateStatusUuid = data.uuid;
        this.confirmationDialog = true;
      } else {
        return false;
      }
    },
    updateStatus(value, uuid) {
      this.$store
        .dispatch(PATCH, {
          url: `project-milestone/status`,
          data: {
            status: value,
            uuid: uuid,
          },
        })
        .then(() => {
          this.confirmationDialog = false;
          this.getAllMilestones();
        })
        .catch((error) => {
          console.log({ error });
        });
    },
    getTextColor(data) {
      if (data == 1) {
        return "green--text";
      }
      if (data == 2) {
        return "red--text";
      }
    },
    getColor(data) {
      if (data == 1) {
        return "green";
      }
      if (data == 2) {
        return "red";
      }
    },
    setProjectBalance() {
      if (this.milestoneCreate && this.milestoneCreate.uuid) {
        this.projectBalance =
          Number(this.detail?.project_balance) +
          Number(this.milestoneCreate.amount);
      } else {
        this.projectBalance = Number(this.detail?.project_balance);
      }
    },
    selectInvoiceType() {
      if (this.detail.status != 2) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Please note, without an accepted quotation, a milestone cannot be created"
          )
        );
        return false;
      }
      if (this.detail.project_value == 0 || this.detail.project_value < 0) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Quotation amount is 0 ,you cannot create milestone")
        );
        return false;
      }
      if (this.milestoneList && this.milestoneList?.length > 0) {
        this.createMilestone();
        return false;
      }
      this.confirmDialog = true;
    },
    createMilestone() {
      this.confirmDialog = false;
      (this.milestoneCreate = {
        project: 0,
        name: null,
        description: null,
        /*  date: null, */
        uuid: null,
        /*    reminder_date: null, */
        amount_type: 2,
        milestone_percentage: 0,
        amount: 0,
        project_balace: null,
        attachments: [],
      }),
        (this.milestoneDialog = true);
      this.setProjectBalance();
    },
    updateFiles(param) {
      this.milestoneCreate.attachments = param;
    },

    percentageKeyup($event) {
      if (String(this.milestoneCreate.milestone_percentage).includes(".")) {
        let percentage = String(
          this.milestoneCreate.milestone_percentage
        ).split(".");
        if (percentage[1].length > 1) {
          $event.preventDefault();
        }
      }
      if (
        this.projectBalance <
        Number(this.milestoneCreate.milestone_percentage / 100) *
          Number(this.detail?.project_value)
      ) {
        this.milestoneCreate.milestone_percentage = 0;
      }
      const percentage =
        this.milestoneCreate.milestone_percentage + Number($event.key);
      if (percentage > 100) {
        $event.preventDefault();
      }
    },
    checkPercentage() {
      if (
        this.projectBalance <
        Number(this.milestoneCreate.milestone_percentage / 100) *
          Number(this.detail?.project_value)
      ) {
        this.milestoneCreate.milestone_percentage = 0;
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Percentage Can not be greater then remaining percentage."
          )
        );
        return false;
      }
    },
    createOrUpdate() {
      const _this = this;
      if (!_this.$refs.milestoneForm.validate()) {
        return false;
      }
      (_this.milestoneCreate.amount =
        this.milestoneCreate.amount_type == 1
          ? _this.milestoneCreate.amount
          : Number(_this.milestoneCreate.milestone_percentage)),
        (_this.milestoneCreate.quotation = _this.detail.id);
      _this.milestoneCreate.project = 0;
      let url = "";
      let requst = "";

      if (_this.milestoneCreate.amount <= 0) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Amonut Can not be less then zero.")
        );
        return false;
      }
      if (_this.milestoneCreate.uuid) {
        url = `quotation-milestone/${_this.milestoneCreate.uuid}`;
        requst = PUT;
      } else {
        url = `quotation-milestone/`;
        requst = POST;
      }
      _this.dataLoading = true;
      _this.$store
        .dispatch(requst, {
          url: url,
          data: _this.milestoneCreate,
        })
        .then(() => {
          _this.accept_dialog = false;
          _this.$emit("reloadData", true);
          _this.getAllMilestones();
          _this.milestoneDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },

    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.milestoneCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 99) {
        let trimValue = finalval.substring(0, 100);
        this.milestoneCreate.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.milestoneCreate.description &&
        this.milestoneCreate.description.length > 99
      ) {
        e.preventDefault();
      }
    },
    getAllMilestones() {
      const _this = this;
      _this.dataLoading = true;
      _this.$store
        .dispatch(GET, { url: `quotation-milestone/${this.detail.id}` })
        .then(({ data }) => {
          _this.milestoneList = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.dataLoading = false;
        });
    },
    getSingle(uuid) {
      if (uuid) {
        const _this = this;
        _this.milestoneDialog = true;
        _this.$store
          .dispatch(GET, { url: `quotation-milestone-single/${uuid}` })
          .then(({ data }) => {
            _this.singleMilestone = data;
            /*  _this.singleMilestone.amount = _this.lodash.toSafeInteger(
              _this.singleMilestone.amount
            ); */
            _this.singleMilestone.amount_type = _this.lodash.toSafeInteger(
              _this.singleMilestone.amount_type
            );
            _this.milestoneCreate.uuid = _this.singleMilestone.uuid;
            (_this.milestoneCreate.name = _this.singleMilestone.name
              ? _this.singleMilestone.name
              : null),
              (_this.milestoneCreate.description = _this.singleMilestone
                .description
                ? _this.singleMilestone.description
                : null),
              (_this.milestoneCreate.date = _this.singleMilestone.date
                ? _this.singleMilestone.date
                : moment().format("YYYY-MM-DD")),
              (_this.milestoneCreate.reminder_date = _this.singleMilestone
                .reminder_date
                ? _this.singleMilestone.reminder_date
                : moment().format("YYYY-MM-DD")),
              (_this.milestoneCreate.amount = _this.singleMilestone.amount
                ? _this.singleMilestone.amount
                : null),
              (_this.milestoneCreate.milestone_percentage = _this
                .singleMilestone.progress
                ? _this.singleMilestone.progress
                : null),
              (_this.milestoneCreate.amount_type = _this.singleMilestone
                .amount_type
                ? this.lodash.toSafeInteger(_this.singleMilestone.amount_type)
                : 1);
            _this.setProjectBalance();
            /*  this.$emit('reloadData',true); */
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }
    },
  },
  mounted() {
    /*  this.$emit('reloadData',true); */
  },
  computed: {
    /* ...mapGetters(["errors"]), */
    calculatedMilestoneAmount() {
      if (this.milestoneCreate.milestone_percentage > 0) {
        const amount =
          this.detail?.project_balance /
          this.milestoneCreate.milestone_percentage;
        let a = amount * 100;
        return a.toFixed(2);
      } else {
        return 0;
      }
    },
    calculatedMilestoneAmountByPercentage() {
      return (
        Number(this.milestoneCreate.milestone_percentage / 100) *
        Number(this.detail && this.detail.project_value)
      );
    },
  },
};
</script>
